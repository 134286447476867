import { createStore } from 'vuex'
import { fabric } from 'fabric-with-erasing'

export default createStore({
  state: {
    fontList: [], // 字体列表
    fontSpecialEffect: [], // 文字特殊样式列表
    operationFaceType: 'setting', // 操作面板类型(setting设置, layer图层, colourMixing调色)
    // 海报相关对象
    boxCanvas: null, // 总画布对象
    layerList: [], // 图层列表(除矩形框)
    // 画布转json自定义对象列表
    toJSONCustomKeyList: [
      'id', 'selectable', 'radius', 'isTemplate', 'imgBase', 'effects', 'usedInfo'
    ],
    // 画布信息
    canvasInfo: {
      fileName: '未命名', // 文件全名
      selectIdList: [], // 选中图层id集合
      selectTypeList: [], // 选中图层类型集合
      selectLayerList: [], // 选中图层对象集合
      backgroundColor: 'rgba(255,255,255,1)', // 背景颜色
      width: 0, // 海报宽
      height: 0, // 海报高
      zoom: 1 // 画布缩放,
    },
    // 选中对象
    selectLayer: {
      object: null, // 选中对象
      objectW: 0, // 选中对象 - 宽
      objectH: 0, // 选中对象 - 高
      top: 0,
      left: 0
    },
    // 操作对象
    operateData: {
      stepIndex: -1, // 当前操作步数
      saveOperateList: [], // 已保存的操作列表
      deleteOperateList: [] // 已删除的操作列表
    },
    // 右键对象
    rightMenu: {
      show: false, // 是否显示
      top: 0, // 位置信息
      left: 0 // 位置信息
    },
    selectLayerId: ''// 选中图层的id，点击图层外不会丢失
  },
  getters: {},
  mutations: {
    /**
     * 总画布信息更新
     */
    updateBoxCanvas: (state, payload) => {
      if (payload.boxCanvas) state.boxCanvas = payload.boxCanvas
      if (payload.layerList) state.layerList = payload.layerList
      if (state.boxCanvas) {
        // 更新缩放值
        const zoom = Number(state.boxCanvas.getZoom()) // 获取缩放值
        state.canvasInfo.zoom = zoom.toFixed(1) // 更新画布缩放值
        // 更新选中对象
        state.selectLayer.object = state.boxCanvas.getActiveObject()
        if (state.selectLayer.object) {
          state.selectLayer.objectW = Math.round(state.selectLayer.object.width * state.selectLayer.object.scaleX)
          state.selectLayer.objectH = Math.round(state.selectLayer.object.height * state.selectLayer.object.scaleY)
          state.selectLayer.top = Math.round(state.selectLayer.object.top)
          state.selectLayer.left = Math.round(state.selectLayer.object.left)
        }
      }
    },

    /**
     * 海报画布信息更新
     */
    setCanvasInfo: (state, payload) => {
      // 参数校验
      if (!state.boxCanvas) return
      // 对象更新(Boolean类型用-1和1代表false和true)
      if (payload.width) state.canvasInfo.width = payload.width
      if (payload.height) state.canvasInfo.height = payload.height
      if (payload.selectLayerList) {
        state.canvasInfo.selectLayerList = payload.selectLayerList
        state.canvasInfo.selectIdList = []
        state.canvasInfo.selectTypeList = []
        payload.selectLayerList.forEach(function (item) {
          state.canvasInfo.selectIdList.push(item.id)
          state.canvasInfo.selectTypeList.push(item.type)
        })
      }
      if (payload.backgroundColor) state.canvasInfo.backgroundColor = payload.backgroundColor
      // 重新渲染
      state.boxCanvas.requestRenderAll()
    },

    /**
     * 图层属性更新
     */
    updateCanvasLayerById: (state, payload) => {
      // 参数校验
      if (!state.boxCanvas) return
      let id = payload.id
      if (!id && state.canvasInfo.selectIdList.length > 0) id = state.canvasInfo.selectIdList[0]
      const layer = state.boxCanvas.getObjects().find((item) => item.id === id)
      if (!layer) return
      // 图层更新(Boolean类型用-1和1代表false和true)
      if (payload.width || payload.width === 0) layer.set('width', Number(payload.width))
      if (payload.height || payload.height === 0) layer.set('height', Number(payload.height))
      if (payload.scaleX || payload.scaleX === 0) layer.set('scaleX', Number(payload.scaleX))
      if (payload.scaleY || payload.scaleY === 0) layer.set('scaleY', Number(payload.scaleY))
      if (payload.top || payload.top === 0) layer.set('top', Number(payload.top))
      if (payload.left || payload.left === 0) layer.set('left', Number(payload.left))
      if (payload.fill) layer.set('fill', payload.fill) // 字体颜色
      if (payload.fontSize) layer.set('fontSize', payload.fontSize) // 字体大小
      if (payload.fontFamily) layer.set('fontFamily', payload.fontFamily) // 字体
      if (payload.fontStyle) layer.set('fontStyle', payload.fontStyle) // 斜体
      if (payload.textAlign) layer.set('textAlign', payload.textAlign) // 字体对齐
      if (payload.fontWeight) layer.set('fontWeight', payload.fontWeight) // 字体加粗
      if (payload.underline) layer.set('underline', payload.underline > 0) // 下划线
      if (payload.linethrough) layer.set('linethrough', payload.linethrough > 0) // 中划线
      if (payload.lineHeight || payload.lineHeight === 0) layer.set('lineHeight', Number(payload.lineHeight)) // 行间距
      if (payload.charSpacing || payload.charSpacing === 0) layer.set('charSpacing', Number(payload.charSpacing)) // 字间距
      if (payload.opacity || payload.opacity === 0) layer.set('opacity', Number(payload.opacity)) // 不透明度
      // 重新渲染
      state.boxCanvas.requestRenderAll()
    },

    /**
     * 更新右键菜单
     */
    setRightMenu: (state, payload) => {
      // 参数校验
      if (!state.boxCanvas) return
      // 对象更新
      state.rightMenu.show = !!payload.show
      if (payload.top) state.rightMenu.top = payload.top
      if (payload.left) state.rightMenu.left = payload.left
    },

    /**
     * 更新字体列表
     */
    updateFontList: (state, payload) => {
      if (payload.fontList) state.fontList = payload.fontList
    },

    /**
     * 初始化操作历史
     */
    initOperateHistory: (state, payload) => {
      state.operateData = {
        stepIndex: -1, // 当前操作步数
        saveOperateList: [], // 已保存的操作列表
        deleteOperateList: [] // 已删除的操作列表
      }
    },

    /**
     * 保存操作历史
     */
    saveOperateHistory (state) {
      if (!state.boxCanvas) return
      const json = state.boxCanvas.toDatalessJSON(state.toJSONCustomKeyList)
      json.objects.forEach(function (item) { item.isTemplate = true }) // 加入JSON中的图层不计入历史
      state.operateData.saveOperateList.push(json)
      state.operateData.stepIndex += 1
      if (state.operateData.stepIndex > 50) {
        state.operateData.saveOperateList.splice(state.operateData.saveOperateList.length - 1, 1)
        state.operateData.stepIndex -= 1
      }
      state.operateData.deleteOperateList = []
      // console.log('保存操作历史: ', state.operateData.saveOperateList)
    },

    /**
     * 后退操作历史
     */
    leftOperateHistory (state) {
      if (!state.boxCanvas) return
      const saveList = state.operateData.saveOperateList
      if (saveList.length < 2) return
      state.operateData.stepIndex -= 1
      if (state.operateData.deleteOperateList.length === 0) {
        for (let i = 0; i < 99; i++) {
          state.boxCanvas.clear()
          if (state.boxCanvas.isEmpty()) break
        }
      }
      state.boxCanvas.loadFromJSON(saveList[state.operateData.stepIndex], function () {
        // 同步历史操作列表
        state.operateData.deleteOperateList.push(saveList[saveList.length - 1])
        state.operateData.saveOperateList.splice(saveList.length - 1, 1)
        // 刷新选中
        const selectObjects = state.boxCanvas.getObjects()
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
          canvas: state.boxCanvas
        })
        state.boxCanvas.setActiveObject(activeSelection)
        state.boxCanvas.discardActiveObject()
        state.boxCanvas.requestRenderAll()
      })
      // console.log('后退操作历史: ', state.operateData.saveOperateList)
    },

    /**
     * 前进操作历史
     */
    rightOperateHistory (state) {
      if (!state.boxCanvas) return
      const saveList = state.operateData.saveOperateList
      const delList = state.operateData.deleteOperateList
      if (delList.length < 1) return
      state.operateData.stepIndex += 1
      state.operateData.saveOperateList.push(delList[delList.length - 1])
      state.boxCanvas.loadFromJSON(saveList[state.operateData.stepIndex], function () {
        // 同步历史操作列表
        state.operateData.deleteOperateList.splice(delList.length - 1, 1)
        // 刷新选中
        const selectObjects = state.boxCanvas.getObjects()
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
          canvas: state.boxCanvas
        })
        state.boxCanvas.setActiveObject(activeSelection)
        state.boxCanvas.discardActiveObject()
        state.boxCanvas.requestRenderAll()
      })
      // console.log('前进操作历史: ', state.operateData.saveOperateList)
    },

    /**
     * 更新文字特殊样式列表
     */
    addFontSpecialEffect (state, payload) {
      state.fontSpecialEffect = payload
    },

    /**
     * 更新文字特殊样式列表
     */
    setOperationFaceType (state, payload) {
      state.operationFaceType = payload
    },

    uploadSelectLayerId (state, id) {
      state.selectLayerId = id
    }
  },
  actions: {},
  modules: {}
})
